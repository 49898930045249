#core-features-section {
  display: flex;
  padding: 128px 32px;
  padding-bottom: 64px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: #FBFBFB;
  gap: 24px;
}

#core-features-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.core-features {
  color: #18181A;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.4px;
  margin: 0;
}

#features {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

#feature {
  display: flex;
  height: 122px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  background: #FFF;
  border: none;
  margin: 24px;
}

.symbol-style {
  display: inline-block;
  width: 5px;
  height: 20px;
  margin-right: 10px;
  color: var(--Secondary-color, #0F54DB);
  background-color: var(--Secondary-color, #0F54DB);
}

#feature-name {
  color: var(--neutral-neutral-01, #18181A);
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: left;
}

#feature-quote {
  color: var(--bright-dark, #140033);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.bottom-text {
  color: var(--neutral-neutral-01, #18181A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding-top: 10px;
  margin-bottom: 0;
}

.icon-row {
  margin-top: 24px;
}

#lower {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FBFBFB;


}

#core-features-lower-section {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding-bottom: 128px;
}

#icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

#icon-img {
  width: 60.84px;
  height: 59.994px;
}

#icon-text {
  color: var(--neutral-neutral-01, #18181A);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}


#core-features-support-section {
  display: flex;
  padding: 128px 200px;
  padding-bottom: 64px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

#donate-button {
  display: flex;
  width: 132px;
  height: 61px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--Redius, 2px);
  background: var(--Primary-color, #FFC530);
  color: #372A06;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 32px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #feature {
    height: 165px;
  }
}