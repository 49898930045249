#signup-email-container {
  background-color: #FBFBFB;
  max-width: 1440px;
  height: 60rem;
  margin: 0;
  padding: 8rem 25.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Below are the css styles for sign up title. */
.signup-title-section-row {
  height: 2.5rem;
}

.signup-title-section {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  height: 100%;
}

.signup-title {
  color: #333;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  text-transform: capitalize;
}

/* Below are the css styles for sign up form. */
.signup {
  height: 39.5rem;
  margin: 0 1rem;
}