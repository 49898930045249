footer {
  background: #FFF;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
}

.footer-left-side,
.footer-right-side {
  margin: auto;
  width: auto;
}

.footer-left-side {
  text-align: left;
}

.footer-right-side {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
}

.left-side h4,
.left-side p {
  padding-bottom: 1rem;
  font-weight: bold;
}

.footer-logo {
  width: 60px;
  height: 60px;
  padding-right: 0.4rem;
}

.footer-text-logo {
  width: 48px;
  height: 48px;
}

.footer-text,
.copy-right,
.sign-up-text {
  font-family: sans-serif;
  color: #18181A;
}

.footer-text {
  color: var(--neutral-neutral-01, #18181A);
  font-family: PoetsenOne;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.366px;
}

.copy-right {
  width: 201px;
  height: 38px;
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.sign-up-text {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.icon,
.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: #18181A;
  font-size: 1.8rem;
  padding-right: 1.5rem;
}

.subscribe-form button {
  width: 150px;
  height: 48px;
  background-color: #FFC530;
  border-color: #FFC530;
  border-radius: 2px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #18181A;
  text-transform: uppercase;
  text-align: center;
  margin: 1rem;
  border: none;
  box-shadow: none;
}

.split-line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 0px;
  border: 1px solid #18181A;
  transform: rotate(90deg);
}

.subscribe-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.subscribe-form input {
  width: 340px;
  height: 48px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #AFAFAF;
  background-color: #F4F4F4;
}

.social-icons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Responsive */
@media screen and (max-width: 767px) {
  footer {
    box-sizing: border-box;
    padding: 0 0;
  }

  .footer-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .footer-right-side {
    width: 100%;
    padding: 2rem 2rem;
  }

  .subscribe-form {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 1rem auto;
  }

  .subscribe-form button {
    width: 100%;
    margin: 0.5rem;
  }

  .subscribe-form input {
    width: 100%;
    margin: 0.5rem;
  }

  .sign-up-text {
    text-align: center;
  }

  .button {
    width: 80%;
  }

  .footer-left-side {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%
  }

  .footer-text {
    text-align: justify;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%
  }

  .icon {
    padding: 0.5rem;
  }

  .split-line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 0px;
    border: 1px solid #FFFFFF;
    transform: rotate(180deg);
  }

  .brand-container {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  footer {
    max-width: 991px;
    box-sizing: border-box;
    padding: 0 0;
  }

  .footer-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .subscribe-form {
    justify-content: space-around;
  }

  .footer-right-side {
    width: 100%;
    padding: 2rem;
    align-items: center;
    text-align: center;
  }

  .footer-left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    margin: 0;
  }

  .icon {
    padding: 1rem;
  }

  .copy-right {
    align-items: center;
    text-align: center;
  }

  .split-line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 0px;
    border: 1px solid #FFFFFF;
    transform: rotate(180deg);
    margin-bottom: 1rem;
  }

  .sign-up-text {
    text-align: center;
  }

  .brand-container {
    justify-content: center;
  }
}

@media screen and (min-width: 992px) {
  body {
    background-color: white;
  }

  .footer {
    height: auto;
    margin: 0 auto;
    padding: 0 auto;
  }

  .footer-container {
    margin: 1 rem;
    padding: 0;
    max-width: 1440px;
    height: 264px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-left-side {
    padding-left: 4rem;
    padding-right: 0rem;
  }

  .footer-right-side {
    padding-bottom: 2.5rem;
    padding-top: 0rem;
  }

  button {
    color: #FFC530;
  }
}