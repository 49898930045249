#volunteer-section {
  width: 70%;
  margin: 128px auto;
}

#volunteer {
  width: 100%;
  border: none;
  --bs-card-bg: transparent;
  display: flex;
  align-items: center;
  align-self: stretch;
}

.our-volunteers {
  align-self: stretch;
  color: #18181A;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.4px;
}

#volunteers {
  display: flex;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}

#volunteer-profile-pic {
  height: auto;
  border-radius: 50%;
}

#volunteer-name {
  color: var(--neutral-neutral-01, #18181A);
  text-align: center;
  align-self: stretch;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.4px;
}

#volunteer-quote {
  height: 176px;
  text-align: left;
  color: var(--neutral-neutral-01, #18181A);
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#volunteer-interview {
  color: var(--Secondary-color, #0F54DB);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width: 375px) and (max-width: 765px) {
  #volunteer-section {
    width: 50%;
    padding: 0;
  }

  #our-story-brief-description {
    margin-top: 30%;
    padding: 0;
  }

  #our-story-brief-text {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  #volunteer {
    padding: 0;
    height: 80%;
    margin-bottom: 2.5rem;
  }

  #volunteer:last-child {
    margin-bottom: 0;
  }

  #volunteer-profile-pic {
    width: 86%;
  }

  #volunteer-name {
    margin-top: 0.625rem;
    margin-bottom: 1rem;
    font-weight: bolder;
  }

  #volunteer-quote {
    height: 120px;
    font-size: 15px;
  }

  #volunteer-interview {
    margin: 0;
    padding: 0;
  }

  #volunteer-interview {
    margin: 10px;
  }
}

@media screen and (min-width: 766px) and (max-width: 779px) {
  #volunteer-quote {
    height: 100px;
    font-size: 15px;
  }

  #volunteer-profile-pic {
    width: 60%;
    height: auto;
    border-radius: 50%;
  }

  #our-story-brief-text {
    text-align: left;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  #volunteer-interview {
    margin: 20px;
  }
}

@media screen and (min-width: 780px) and (max-width: 1010px) {
  .volunteer-element {
    padding: 3px;
  }

  #volunteer-profile-pic {
    width: 80%;
    height: auto;
    border-radius: 50%;
  }

  #volunteer-name {
    line-height: 22px;
    font-size: 20px;
    margin-bottom: 8px;
  }

  #volunteer-quote {
    height: 200px;
    font-size: 13px;
  }
}

@media screen and (min-width: 1011px) and (max-width: 1399px) {
  #volunteer-profile-pic {
    width: 90%;
    height: auto;
    border-radius: 50%;
  }

  #volunteer-name {
    margin-bottom: 12px;
  }

  #volunteer-quote {
    font-size: 14px;
  }
}

@media screen and (min-width: 1440px) {
  #volunteer-profile-pic {
    width: 292px;
    height: 292px;
    border-radius: 600px;
  }

  #volunteer-section {
    display: flex;
    width: 1008px;
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  #volunteer-name {
    margin-top: 8px;
  }

  #volunteer-quote {
    height: 210px;
  }
}